import React from 'react';
import Link from 'gatsby-link';

import { ImageProps } from './models';

const withLink = (Component: React.ComponentType<ImageProps>) => (props: ImageProps) =>
  props.link ? (
    <Link className="image__link" to={props.link}>
      <Component {...props} />
    </Link>
  ) : (
    <Component {...props} />
  );

export default withLink;
