import React, { FC } from 'react';
import { MEN_CATEGORY } from 'utils/constants';
import classNames from 'classnames';

import { ProductPriceSpiderProps } from './models';

import './ProductPriceSpider.scss';

const ProductPriceSpider: FC<ProductPriceSpiderProps> = ({ productEAN, currentCategory }) => {
  return (
    <div
      className={classNames('product__sk-pricespider', {
        'banner--men': currentCategory === MEN_CATEGORY,
      })}
      data-testid="product-price-spider"
    >
      <script>{`PriceSpider.insertWidget({ "sku": "${productEAN}" })`}</script>
    </div>
  );
};

export default ProductPriceSpider;
