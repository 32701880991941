import React, { FC } from 'react';
import classNames from 'classnames';
import Img from 'gatsby-image';

import isEmpty from 'lodash/isEmpty';
import PlainImage from './PlainImage';
import withLink from './withLink';

import { ImageProps } from './models';

const Image: FC<ImageProps> = ({
  className,
  alt,
  localImage,
  sizes,
  critical,
  performanceMode = false,
  lazyLoad = true,
}) => {
  const CSSclassNames = classNames('local-image', className);

  if (!localImage || !localImage.childImageSharp) {
    return null;
  }
  const fluid = { ...localImage.childImageSharp.fluid };
  const fixed = { ...localImage.childImageSharp.fixed };

  if (sizes) {
    fluid.sizes = sizes;
  }

  return (
    <div className={CSSclassNames}>
      {performanceMode ? (
        <PlainImage
          className={className}
          src={localImage.childImageSharp.fluid.src}
          alt={alt}
          srcSetWebp={localImage.childImageSharp.fluid.srcSetWebp}
          srcSet={localImage.childImageSharp.fluid.srcSet}
          isLocalImage
        />
      ) : (
        <Img
          loading={lazyLoad ? 'lazy' : 'eager'}
          className="image"
          fixed={!isEmpty(fixed) ? fixed : undefined}
          fluid={!isEmpty(fluid) ? fluid : undefined}
          {...{ alt, critical }}
        />
      )}
    </div>
  );
};

export default withLink(Image);
